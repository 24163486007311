import React from 'react';

import settingsIcon from "../resources/settingsIcon.png"

function UnderConstructionRoute(props) {
    return (<React.Fragment>
        <div style={{width: window.innerWidth, height: window.innerHeight}} className='d-flex justify-content-center align-items-center flex-column'>
            <img src={settingsIcon} alt="under construction" width={"200px"} style={{paddingBottom:"50px"}}/>
            <br />
            <strong><h1>Sitio en construcción</h1></strong>

        </div>
    </React.Fragment>);
}

export default UnderConstructionRoute;