import React from "react";

import Carousel from "react-bootstrap/Carousel";

function PictureCarousel(props) {
  const renderCarrouselItem = props.carouselItems.map((item, index) => {
    if (!item.includes("logo")) {
      return (
        <Carousel.Item key={index}>
          <img
            className="d-block"
            src={item}
            alt="Third slide"
            width={"100%"}
          />
          <Carousel.Caption style={{ textAlign: "center", color: "black" }}>
            {/* <div className="carousel_caption_corp">Contáctate</div> */}
          </Carousel.Caption>
        </Carousel.Item>
      );
    } else {
      return null;
    }
  });

  return (
    <div>
      <Carousel className="carousel">{renderCarrouselItem}</Carousel>
    </div>
  );
}

export default PictureCarousel;
