import React, { useState } from "react";

import Contacts from "../components/contacts";
import Header from "../components/header";
import PictureCarousel from "../components/pictureCarouselContacts";

import LoadingSpinner from "../components/loadingSprinner";
import AlertComponent from "../components/alert";

import Button from "../components/button";
import { useBackend } from "../context/backendContext";

import sellsIconContacts from "../resources/sellsIconContacts.png";
import productionIcon from "../resources/productionIcon.png";

import GMap from "../components/gmap";

function ContactsRoute(props) {
  let alertInterval;
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState("danger");
  const [alertText, setAlertText] = useState("Alert alert Alert!");

  const [showSpinner, setShowSpinner] = useState(false);

  const handleShowAlert = (message, variant, time) => {
    setAlertVariant(variant);
    setAlertText(message);
    setShowAlert(true);
    alertInterval = setInterval(() => {
      setShowAlert(false);
      clearInterval(alertInterval);
    }, time * 1000);
  };

  const [firstName, setFirstName] = useState("");
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const [lastName, setLastName] = useState("");
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const [email, setEmail] = useState("");
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const [phone, setPhone] = useState("");
  const handlePhoneChange = (e) => {
    if (!isNaN(e.target.value)) {
      setPhone(e.target.value);
    }
  };

  const [message, setMessage] = useState("");
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const { sendContactForm } = useBackend();
  const handleContactFormSubmit = async () => {
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      phone === "" ||
      message === ""
    ) {
      handleShowAlert(
        "Llene todos los campos del formulario antes de enviar.",
        "warning",
        3
      );
      return;
    }

    const dataMessage = {
      name: firstName,
      lastname: lastName,
      email: email,
      phone: phone,
      message: message,
    };
    try {
      setShowSpinner(true);
      const backendResponse = await sendContactForm(dataMessage);
      setShowSpinner(false);
      handleShowAlert(backendResponse["message"], "success", 3);
      restFormFields();
    } catch (error) {
      setShowSpinner(false);
      handleShowAlert(error.message, "danger", 3);
    }
  };

  function restFormFields() {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setMessage("");
  }

  // const [width, setWidth] = useState(0);
  // const [height, setHeight] = useState(0);
  // const [wFactor, setWFactor] = useState(0);
  // const [hFactor, setHFactor] = useState(0);

  // useEffect(() => {
  //   function handleWindowResize() {
  //     if (window.innerWidth >= 1160) {
  //       setWFactor(0.45);
  //       setHFactor(1);
  //     } else if (window.innerWidth >= 660) {
  //       setWFactor(0.92);
  //       setHFactor(0.95 * 0.8);
  //     } else {
  //       setWFactor(0.84);
  //       setHFactor(0.95 * 0.8);
  //     }
  //     setWidth(window.innerWidth * wFactor);
  //     setHeight(window.innerHeight * hFactor * 0.5);
  //   }
  //   handleWindowResize();
  //   window.addEventListener("resize", handleWindowResize);

  //   return () => {
  //     window.removeEventListener("resize", handleWindowResize);
  //   };
  // }, [hFactor, wFactor]);

  return (
    <React.Fragment>
      <div style={{ height: "100%" }}>
        <Contacts />
        <Header selected={4} />
        <div className="contacts_container">
          <div className="contacts_horizontal">
            <div className="contacts_content">
              <div className="contacts_carousel">
                <PictureCarousel />
              </div>
            </div>
            <div className="contacts_content">
              <div className="contacts_form flex-fill">
                <div className="contacts_title">¿Qué podemos hacer por tí?</div>
                <form className="text-center">
                  <LoadingSpinner show={showSpinner} />
                  <AlertComponent
                    variant={alertVariant}
                    show={showAlert}
                    text={alertText}
                    maxWidth={"100%"}
                  />
                  <div className="row row-cols-1 row-cols-sm-2">
                    <div className="col d-flex justify-content-center mb-3">
                      <input
                        className="form-control text-center"
                        autoFocus={false}
                        required={true}
                        onChange={handleFirstNameChange}
                        value={firstName}
                        placeholder="Nombre*"
                        type="text"
                      />
                    </div>
                    <div className="col d-flex justify-content-center mb-3">
                      <input
                        className="form-control text-center"
                        autoFocus={false}
                        required={true}
                        onChange={handleLastNameChange}
                        value={lastName}
                        placeholder="Apellido*"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="row row-cols-1 row-cols-sm-2">
                    <div className="col d-flex justify-content-center mb-3">
                      <input
                        className="form-control text-center"
                        autoFocus={false}
                        required={true}
                        onChange={handleEmailChange}
                        value={email}
                        placeholder="Email*"
                        type="email"
                      />
                    </div>
                    <div className="col d-flex justify-content-center mb-3">
                      <input
                        className="form-control text-center"
                        autoFocus={false}
                        required={true}
                        onChange={handlePhoneChange}
                        value={phone}
                        placeholder="Teléfono*"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center">
                    <div className="col d-flex justify-content-center">
                      <textarea
                        className="form-control"
                        name="message"
                        id="message"
                        cols="30"
                        rows="5"
                        placeholder="Describe tu requiremiento*"
                        required={true}
                        value={message}
                        onChange={handleMessageChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      show={!showSpinner}
                      onClick={handleContactFormSubmit}
                      value="Enviar"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="contacts_horizontal">
            <div className="contacts_content">
              <div
                className="contacts_contacts d-flex flex-fill"
                style={{ minHeight: "300px" }}
              >
                <div className="contacts_contacts_item">
                  <img src={sellsIconContacts} alt="sellsIcon" width={"70px"} />
                  <div className="d-flex flex-fill flex-column">
                    <div className="contacts_contacts_title">Ventas</div>
                    <div className="contacts_contacts_text">0995298554</div>
                  </div>
                </div>
                <div className="contacts_contacts_item">
                  <img src={productionIcon} alt="sellsIcon" width={"70px"} />
                  <div className="d-flex flex-fill flex-column">
                    <div className="contacts_contacts_title">Producción</div>
                    <div className="contacts_contacts_text">02807533</div>
                  </div>
                </div>
                <div className="contacts_contacts_item">
                  <div className="contacts_contacts_text text-center flex-fill">
                    Calle de los Arupos E9-18 y Pasaje E9
                  </div>
                </div>
              </div>
            </div>
            <div className="contacts_content">
              <div className="contacts_map d-flex flex-fill">
                <GMap />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ContactsRoute;
