import React from "react";

function Button(props) {
  if (props.show) {
    return (
      <div className="button" onClick={props.onClick}>
        {props.value}
      </div>
    );
  }
}

export default Button;
