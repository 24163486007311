import React, { useEffect, useState } from "react";

import Contacts from "../components/contacts";
import Header from "../components/header";

import misionIcon from "../resources/misionIcon.png";
import visionIcon from "../resources/visionIcon.png";
import valuesIcon from "../resources/valuesIcon.png";

import aboutusLogo from "../resources/aboutusLogo.png";

function AboutUsRoute(props) {
  const [seconds, setSeconds] = useState(0);
  const [backgroundClass, setBackgroundClass] = useState("bg1");

  useEffect(() => {
    const classes = ["bg1", "bg2", "bg3"];
    let interval = null;
    interval = setInterval(() => {
      setSeconds((seconds) => (seconds < 2 ? seconds + 1 : (seconds = 0)));
      setBackgroundClass(classes[seconds]);
      // console.log(seconds);
    }, 5000);

    return () => clearInterval(interval);
  }, [seconds]);

  if (false) {
    return (
      <React.Fragment>
        <h1>Loading...</h1>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div style={{ height: "100%" }}>
          <Contacts />
          <Header selected={1} />
          <div className={"aboutus_content " + backgroundClass}>
            <div
              className="title py-3"
              style={{ fontStyle: "italic", fontWeight: "20px" }}
            >
              <h1>RYMMAO CORP.</h1>
              <h2>Investigación, calidad y confianza</h2>
            </div>
            <div className="aboutus_down">
              <div className="aboutus_container">
                <div className="title">
                  <h1>¿Quienes somos?</h1>
                </div>
                <div className="content">
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Esse, corrupti! Commodi, esse. Voluptatem iure praesentium
                    distinctio voluptatum nisi in numquam consequuntur velit
                    labore similique! Aliquam reprehenderit ad minus ullam
                    quasi. Lorem, ipsum dolor sit amet consectetur adipisicing
                    elit. Officia harum corporis voluptatum molestias commodi,
                    tempore ipsum praesentium sequi id aspernatur mollitia
                    deserunt incidunt odio distinctio nisi voluptate beatae
                    magni possimus? Lorem ipsum dolor sit amet consectetur,
                    adipisicing elit.
                  </p>
                </div>
              </div>
              <div className="aboutus_container img_container">
                <img src={aboutusLogo} alt="" width={"250px"} />
              </div>
            </div>
            <div className="aboutus_up">
              <div className="aboutus_container">
                <div className="icon">
                  <img src={misionIcon} alt="misionIcon" width={"100px"} />
                </div>
                <div className="title">
                  <h1>Misión</h1>
                </div>
                <div className="content">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dignissimos quo tempore sint modi, expedita nulla facere
                    numquam aperiam obcaecati? Tenetur deleniti reiciendis,
                    dolor dignissimos unde laborum distinctio nihil voluptate
                    autem.
                  </p>
                </div>
              </div>
              <div className="aboutus_container same_width">
                <div className="icon">
                  <img src={visionIcon} alt="visionIcon" width={"100px"} />
                </div>
                <div className="title">
                  <h1>Visión</h1>
                </div>
                <div className="content">
                  <p>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ex
                    ad a accusantium cupiditate aut fugit eum suscipit, ab
                    voluptas consequuntur quam quia numquam. Tempora atque iusto
                    consequatur ipsa officiis nam.
                  </p>
                </div>
              </div>
              <div className="aboutus_container same_width">
                <div className="icon">
                  <img src={valuesIcon} alt="valuesIcon" width={"100px"} />
                </div>
                <div className="title">
                  <h1>Valores</h1>
                </div>
                <div className="content">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Officia impedit nesciunt reprehenderit error voluptatem
                    voluptatibus dignissimos repellendus placeat quam quo sunt
                    nostrum voluptatum, perspiciatis aliquid dolores qui fugit
                    corrupti! Veniam.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AboutUsRoute;
