import React from "react";
import Contacts from "../components/contacts";
import Header from "../components/header";
import PictureCarousel from "../components/pictureCarouselDistributors";

import fybecaLogo from "../resources/fybecaLogo.png";
import sanaSanaLogo from "../resources/sanaSanaLogo.png";

function DistributorsRoute(props) {
  return (
    <React.Fragment>
      <div style={{ height: "100%", background: "#e9e8e6" }}>
        <Contacts />
        <Header selected={3} />
        <div className="d-flex justify-content-center">
          <div className="carousel_container">
            <div className="home_carousel">
              <PictureCarousel />
            </div>
          </div>
        </div>
        <div>
          <div className="home_content">
            <div className="home_title">Distribuidores autorizados</div>
            <div className="distributors">
              <div className="dist_logo">
                <img src={fybecaLogo} alt="FybecaLogo" width={"300px"} />
              </div>
              <div className="dist_logo">
                <img src={sanaSanaLogo} alt="FybecaLogo" width={"350px"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DistributorsRoute;
