import React, { Component } from "react";

import picture1 from "../resources/contactsImg1.jpg";
import picture2 from "../resources/contactsImg2.jpg";
// import picture3 from "../resources/contactsImg3.jpg";
import Carousel from "react-bootstrap/Carousel";

class PictureCarousel extends Component {
  render() {
    return (
      <div>
        <Carousel className="carousel">
          <Carousel.Item>
            <img
              className="d-block"
              src={picture1}
              alt="Third slide"
              width={"100%"}
            />
            <Carousel.Caption style={{ textAlign: "center", color: "black" }}>
              <div className="carousel_caption_corp">Contáctate</div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block"
              src={picture2}
              alt="Third slide"
              width={"100%"}
            />
            <Carousel.Caption style={{ textAlign: "center", color: "black" }}>
              <div className="carousel_caption_corp">Contáctate</div>
            </Carousel.Caption>
          </Carousel.Item>
          {/* <Carousel.Item>
            <img
              className="d-block"
              src={picture2}
              alt="Third slide"
              width={"100%"}
            />
            <Carousel.Caption style={{ textAlign: "center", color: "black" }}>
              <div className="carousel_caption_corp">Contáctate</div>
            </Carousel.Caption>
          </Carousel.Item> */}
        </Carousel>
      </div>
    );
  }
}

export default PictureCarousel;
