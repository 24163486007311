import React, { useEffect, useRef, useState } from "react";
import Contacts from "../components/contacts";
import Header from "../components/header";

import { useParams } from "react-router-dom";
import { useBackend } from "../context/backendContext";
import PictureCarousel from "../components/pictureCarouselProducts";

function ProductsRoute(props) {
  const [backendPending, setBackendPending] = useState(true);
  const { productId } = useParams();
  const requestServer = useRef(true);
  const { getProductData } = useBackend();
  const [productData, setProductData] = useState();
  const [productImgArray, setProductImgArray] = useState([]);
  const [productSpecsArray, setProductSpecsArray] = useState([]);
  const [currentProductId, setCurrentProductId] = useState(productId);

  const renderProductSpecs = productSpecsArray.map((spec, index, elements) => {
    const specKey = spec.split(":")[0];
    const specValue = spec.split(":")[1];
    return (
      <div className="col d-flex spec_item" key={index}>
        <div>
          <strong>{specKey}:</strong>
        </div>
        <div>{specValue}</div>
      </div>
    );
  });

  useEffect(() => {
    // console.log(productId);
    if (currentProductId !== productId) {
      setBackendPending(true);
      requestServer.current = true;
      setCurrentProductId(productId);
    }

    async function requestProductData() {
      const productDataResult = await getProductData(productId);
      console.log(productDataResult);
      setProductData(productDataResult);
      const urlArray = productDataResult["productimgurl"].split("$");
      setProductImgArray(urlArray);
      const specsArray = productDataResult["productspecs"].split("$");
      setProductSpecsArray(specsArray);
    }

    async function requestAll() {
      await requestProductData();
      setBackendPending(false);
    }

    if (requestServer.current) {
      requestAll();
      requestServer.current = false;
    }
  }, [productId, getProductData, currentProductId]);

  if (backendPending) {
    return (
      <React.Fragment>
        <div style={{ height: "100%", background: "#e9e8e6" }}>
          <Contacts />
          <Header />
          <h1>Loading...</h1>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div style={{ height: "100%", background: "#e9e8e6" }}>
        <Contacts />
        <Header />

        <div className="product_route_container">
          <div className="product_left_container">
            <div className="product_route_logo">
              <img
                src={productImgArray[productImgArray.length - 1]}
                alt="ProductLogo"
                width={"250px"}
              />
            </div>
            <div
              className="carousel_contanier"
              // style={{ border: "2px solid blue" }}
            >
              <div className="product_carousel">
                <PictureCarousel carouselItems={productImgArray} />
              </div>
            </div>
          </div>
          <div className="product_right_container">
            <div className="product_route_description">
              <p>{productData["productdescription"]}</p>
            </div>
            <div className="product_route_specs">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2">
                {renderProductSpecs}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductsRoute;
