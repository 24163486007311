import React from "react";

function AlertComponent(props) {
  if (props.show) {
    return (
      <div className="row d-flex justify-content-center">
        <div
          style={{
            textAlign: "justify",
            maxWidth: props.maxWidth ? props.maxWidth : "60%",
          }}
        >
          <div className={"text-center alert alert-" + props.variant}>
            {props.text}
          </div>
        </div>
      </div>
    );
  }
}

export default AlertComponent;
