import React, { Component } from "react";

import picture1 from "../resources/distImg1.png";
import picture2 from "../resources/distImg2.png";
import picture3 from "../resources/distImg3.png";

import Carousel from "react-bootstrap/Carousel";

class PictureCarousel extends Component {
  render() {
    return (
      <div>
        <Carousel className="carousel">
          <Carousel.Item>
            <img
              className="d-block"
              src={picture1}
              alt="Third slide"
              width={"100%"}
            />
            <Carousel.Caption style={{ textAlign: "center" }}>
              <div className="carousel_caption_corp" style={{color:"white"}}>RYMMAO S.A.</div>
              <div className="carousel_caption_eslogan" style={{color:"white"}}>Más que cosméticos</div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block"
              src={picture2}
              alt="Third slide"
              width={"100%"}
            />
            <Carousel.Caption style={{ textAlign: "center"}}>
              <div className="carousel_caption_corp" style={{color:"white"}}>RYMMAO S.A.</div>
              <div className="carousel_caption_eslogan" style={{color:"white"}}>Más que cosméticos</div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block"
              src={picture3}
              alt="Third slide"
              width={"100%"}
            />
            <Carousel.Caption style={{ textAlign: "center" }}>
              <div className="carousel_caption_corp" style={{color:"white"}}>RYMMAO S.A.</div>
              <div className="carousel_caption_eslogan" style={{color:"white"}}>Más que cosméticos</div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

export default PictureCarousel;
