import React from "react";

import instagramLogo from "../resources/instagram.png";
import facebookLogo from "../resources/facebook.png";

function Contacts(props) {
  return (
    <React.Fragment>
      <div className="contacts">
        <div className="social">
          <img
            src={instagramLogo}
            alt="instagramLogo"
            width={"30px"}
            style={{
              marginLeft: "20px",
              marginRight: "15px",
              marginTop: "7px",
              marginBottom: "5px",
              //   border: "2px solid black",
            }}
          />
          <a
            href="https://www.facebook.com/originalcosmeticsecuador"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={facebookLogo}
              alt="facebookLogo"
              width={"28px"}
              style={{
                marginLeft: "20px",
                marginRight: "15px",
                marginTop: "7px",
                marginBottom: "7px",
                //   border: "2px solid black",
              }}
            />
          </a>
        </div>
        <div className="contacts_spacer"></div>
        <div className="contacts_info">
          <div className="contacts_item">(02)-807533</div>
          <div className="contacts_item">0995298554</div>
          <div className="contacts_item">info@rymmao.com</div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Contacts;
