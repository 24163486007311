import React from "react";
// import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
// import LoadingSpinner from "./loadingSprinner";

// const g_libraries = ["places"];

function GMap(props) {
  // const containerStyle = {
  //   width: props.width,
  //   height: props.height,
  //   borderRadius: "5px",
  // };
  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  //   libraries: g_libraries,
  // });

  // if (!isLoaded) {
  //   return (
  //     <React.Fragment>
  //       <LoadingSpinner />
  //     </React.Fragment>
  //   );
  // }
  return (
    <React.Fragment>
      {/* <GoogleMap
        id="delivery-location"
        zoom={12}
        center={{ lat: -0.18059, lng: -78.48724 }}
        // mapContainerClassName="map-container"
        mapContainerStyle={containerStyle}
      >
        <MarkerF key={3} position={{ lat: -0.18059, lng: -78.48724 }} />
      </GoogleMap> */}
      <iframe
        title="map"
        width={"100%"}
        height={"100%"}
        // style={{ border: "0" }}
        loading="lazy"
        // allowfullscreen
        src={
          "https://www.google.com/maps/embed/v1/place?q=place_id:ChIJoZZoY4GP1ZERYU0ck910KG8&key=" +
          process.env.REACT_APP_GOOGLE_MAPS_API_KEY
        }
      ></iframe>
    </React.Fragment>
  );
}

export default GMap;
