import React from "react";
import Spinner from "react-bootstrap/Spinner";

function LoadingSpinner(props) {
  if (props.show) {
    return (
      <React.Fragment>
        <div className="py-3">
          <Spinner animation="grow" variant="light" />
        </div>
      </React.Fragment>
    );
  }
}

export default LoadingSpinner;
