import React from "react";

import { useNavigate } from "react-router-dom";

function HeaderItem(props) {
  const navigateTo = useNavigate();

  return (
    <React.Fragment>
      <div
        className="header_item"
        onClick={() => {
          navigateTo(props.link);
        }}
        style={props.selected ? { borderBottom: "5px solid black" } : {}}
      >
        {props.caption}
      </div>
    </React.Fragment>
  );
}

export default HeaderItem;
