import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";

import { useBackend } from "../context/backendContext";

function Dropdown(props) {
  const navigateTo = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const handlerShowDropdown = (e) => {
    setShowDropdown(!showDropdown);
  };

  const handlerHideDropdown = (e) => {
    setShowDropdown(false);
  };

  const [backendPending, setBackendPending] = useState(true);
  const requestServer = useRef(true);
  const { getDropDownItems } = useBackend();
  const headerMenuId = props.headerMenuId;
  const [dropdownItems, setDropdownItems] = useState([]);

  useEffect(() => {
    async function getDropdown(headerMenuId) {
      try {
        const getDropDownResult = await getDropDownItems(headerMenuId);
        // console.log(getDropDownResult);
        setDropdownItems(getDropDownResult);
      } catch (error) {
        console.log(error.message);
      }
    }

    async function requestAll() {
      await getDropdown(headerMenuId);
      setBackendPending(false);
    }

    if (requestServer.current) {
      requestAll();
      requestServer.current = false;
    }
  }, [getDropDownItems, headerMenuId]);

  const renderDropdownItems = dropdownItems.map((menuItem) => {
    return (
      <NavDropdown.Item
        key={menuItem.headerdropmenuid}
        eventKey={menuItem.headerdropmenuid}
        onClick={() => {
          navigateTo(
            `${
              menuItem.headermenulink + "/" + menuItem.headerdropmenuforeingkey
            }`
          );
        }}
      >
        {menuItem.productname}
      </NavDropdown.Item>
    );
  });

  if (backendPending) {
    return (
      <React.Fragment>
        <div className="header_dropdown" style={{ color: "transparent" }}>
          Loading...
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div
        className="header_dropdown"
        // style={props.selected ? { borderBottom: "5px solid white" } : {}}
        style={{ border: "2px solid transparent" }}
      >
        <NavDropdown
          title={<span className="header_dropdown_title">{props.caption}</span>}
          id="nav-dropdown"
          show={showDropdown}
          onClick={handlerShowDropdown}
          onMouseEnter={handlerShowDropdown}
          onMouseLeave={handlerHideDropdown}
          active={true}
          style={{ border: "2px solid transparent" }}
        >
          {renderDropdownItems}
        </NavDropdown>
      </div>
    );
  }
}

export default Dropdown;
